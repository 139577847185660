import styled from "styled-components";
import { colors, functions, properties } from "../../../styles";



const selectedValueStyles = `
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${colors.grey15};
  border-radius: 7px;
  border: 1px solid ${colors.grey1};
  border-color: ${colors.grey1};
  transition: ${properties.transition};
  overflow: hidden;
  padding: 16px 20px;
  text-align: left;
`

const Styles = styled.div`
  width: 100%;
  max-width: ${properties.maxFieldWidth};
  position: relative;
  z-index: 2;
  .selected-value {
    ${selectedValueStyles}
  }
  .option-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: ${colors.white};
    padding: 1rem;
    box-shadow: ${properties.boxShadow};
    margin-top: 0.5rem;
    border-radius: 10px;
    z-index: 10;
    .select-search {
      input {
        padding: 7px;
        border-radius: 5px;
        font-size: 0.8rem;
      }
    }
    .options {
      display: flex;
      flex-direction: column;
      gap: 5px;
      overflow-y: auto;
      height: auto;
      max-height: 18rem;
      .option {
        font-size: 0.8rem;
        color: ${colors.grey6};
        &:first-of-type {
          margin-top: 5px;
        }
        .option-button {
          width: 100%;
          text-align: left;
          display: block;
          padding: 7px;
          border: 1px solid ${colors.grey1};
          background-color: ${colors.grey4};
          border-radius: 5px;
          font-size: inherit;
          &.selected {
            border-color: ${colors.primary};
            background-color: ${functions.rgba(colors.primary, 0.1)};
          }
        }
      }
    }
  }
`;

export default Styles;